<template>
  <div>
    <ag-table
      :grid-options="gridOptions"
      :row-data="rowData"
      @emitAddNewRecord="createRecord()"
      @emitDeleteMultipleRecords="deleteTag($event, null, true)"
    />

    <vs-popup
      title="تفاصيل التاغ"
      :active.sync="popupActive"
    >
      <vs-progress
        v-if="isLoading"
        indeterminate
        color="primary"
      />
      <ValidationObserver
        v-slot="{ handleSubmit }"
        ref="validationObserver"
      >
        <form @keydown.enter.prevent="handleSubmit(onSubmit)">
          <div class="vx-row">
            <div class="vx-col w-full text-center">
              <h4 class="my-5">
                الصورة
              </h4>
              <image-uploader
                @fileUploaded="fileUploaded"
                :image-preview="imagePreview"
                ref="imageUploader"
              />
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col w-full">
              <ValidationProvider
                name="اسم التاغ"
                rules="required"
                v-slot="{ errors }"
              >
                <label>الاسم</label>
                <vs-input
                  placeholder="الاسم"
                  v-model="tag.name"
                  class="w-full"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col w-full mb-2">
              <label>نوع التاغ</label>
              <ValidationProvider
                name="نوع التاغ"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  :options="tagTypes"
                  label="label"
                  v-model="tag.type"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col w-full mb-2">
              <label>المشروع</label>
              <v-select
                :options="modules"
                label="label"
                v-model="tag.module"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
              />
            </div>
          </div>

          <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <vs-button
                :disabled="isLoading"
                ycolor="success"
                type="filled"
                @click.prevent="handleSubmit(onSubmit)"
              >
                حفظ
              </vs-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vs-popup>

    <vs-popup
      title="تفاصيل التاغ"
      :active.sync="popupActiveDetails"
    >
      <form>
        <div class="vx-row">
          <img
            v-lazy="tag.image_url"
            class="m-auto custom-image-size"
          >
        </div>
        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/4 w-full">
            <h4>المعرّف : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ tag.id }}</h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <h4>التاغ : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ tag.name }}</h4>
          </div>
        </div>

        <div class="vx-row my-10">
          <div class="vx-col sm:w-1/4 w-full">
            <h4>النوع : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4 v-html="tag.type"/>
          </div>
        </div>

        <div class="vx-row mt-5">
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <vs-button
              ycolor="success"
              type="filled"
              @click="popupActiveDetails=false"
            >
              حسناً
            </vs-button>
          </div>
        </div>
      </form>
    </vs-popup>
  </div>
</template>

<script>
import AgTable from "../shared-components/ag-grid/AgTable";
import AgTableBtnCell from "../shared-components/ag-grid/AgTableBtnCell.vue";
import {RepositoryFactory} from "../../website/website-repositories/websiteRepositoryFactory";
import utilities from "@/app/shared/utilities";
import vSelect from "vue-select";
import {SharedRepositoryFactory} from "@/app/shared/shared-repositories/sharedRepositoryFactory";
import AgTableImageCell from '../../shared/shared-components/ag-grid/AgTableImageCell';
import imageUploader from '../../shared/shared-components/ImageUploader';


const tagsRepo = RepositoryFactory.get("tag");
const sharedRepo = SharedRepositoryFactory.get("generalTypes");

export default {
  name: "TagsMain",
  components: {
    AgTable,
    vSelect,
    imageUploader
  },
  data() {
    return {
      gridOptions: null,
      imagePreview: null,
      popupActiveDetails: false,
      rowData: [],
      tag: {
        id: null,
        name: '',
        type: '',
        image_url: '',
        module: ''
      },
      tagTypes: [],
      modules: [],
      popupActive: false,
      isLoading: true,
      filter: ''
    };
  },
  methods: {
    createColDefs() {
      let self = this;
      return [
        {
          headerName: "المعرف",
          field: "id",
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
          width: 225,
          imagePreview: null,
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          headerName: "التاغ",
          field: "name",
          filter: 'agTextColumnFilter',
        },
        {
          headerName: "الصورة",
          field: 'image_url',
          cellRendererFramework: AgTableImageCell
        },
        {
          headerName: "النوع",
          field: "type",
          filter: 'agTextColumnFilter',
        },
        {
          headerName: "المشروع",
          field: "module",
          filter: 'agTextColumnFilter',
        },
        {
          headerName: "الإجراءات",
          field: "id",
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            deleteRecord: function (id, rowIndex) {
              self.deleteTag(id, rowIndex);
            },
            editRecord: function (tag) {
              self.editRecord(tag);
            },
            viewRecordDetails: function (record) {
              self.viewRecordDetails(record);
            },
            actions: ["view", "edit", "delete"],
          },
        },
      ];
    },

    deleteTag(ids, index, isMultiple = false) {
      this.isLoading = true;
      if (isMultiple) ids = ids.join(",");
      tagsRepo.deleteTag(ids).then((response) => {
        if (response.code === 200) {
          if (isMultiple) this.fetchAllTags();
          else {
            this.rowData.splice(index, 1);
            this.rowData = [...this.rowData];
          }
        }
        this.isLoading = false;
      });
    },

    // used to fire the dialog which response to add a new record in the table
    createRecord() {
      utilities.initObjectMembers(this.tag);
      this.$refs.imageUploader.clearImage();
      this.imagePreview = null;
      this.openPopup();
    },

    // used to fire the dialog which response to edit current record in the table
    editRecord(tag) {
      Object.assign(this.tag, tag);
      this.imagePreview = this.tag.image_url;
      this.openPopup();

    },

    viewRecordDetails(record) {
      this.popupActiveDetails = true;
      this.tag = record;
    },

    openPopup() {
      this.$refs.validationObserver.reset();
      this.popupActive = true;
    },
    fileUploaded(file) {
      this.tag.image_url = file;
    },

    onSubmit() {
      this.isLoading = true;
      if (typeof this.tag.image_url == "string")
        delete this.tag.image_url;
      if (this.tag.id !== null) {
        this.updateTag(this.tag);
      } else {
        this.storeTag(this.tag);
      }
    },

    storeTag(tag) {
      tagsRepo.storeTag(this.$objectToFD(tag)).then((response) => {
        this.rowData.unshift(response.data.data);
        this.handleResponse(response.data);
        this.isLoading = false;
      });
    },

    updateTag(tag) {
      tagsRepo.updateTag(this.$objectToFD(tag), tag.id).then((response) => {
        let index = this.rowData
          .map((tag) => {
            return tag.id;
          })
          .indexOf(response.data.data.id);
        this.rowData[index] = response.data.data;
        this.rerender();
        this.handleResponse(response.data);
        this.isLoading = false;
      });
    },

    handleResponse() {
      this.popupActive = false;
    },

    // used to rerender updated row
    rerender() {
      this.gridApi.setRowData(this.rowData);
    },

    fetchAllTags() {
      tagsRepo.fetchAllTags(this.$route.params.key).then((response) => {
        this.rowData = response;
        this.isLoading = false;
      });
    }
  },
  created() {
    // prepare ag grid options
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
    sharedRepo.fetchGeneralTypes("tag").then((data) => {
      this.tagTypes = data;
    });
    sharedRepo.fetchGeneralTypes("module").then((data) => {
      this.modules = data;
    });
    const $this = this;
    this.$watch(
      () => this.$route.params,
      async () => {
        $this.fetchAllTags();
      },
      {immediate: true}
    );
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  },
};
</script>
